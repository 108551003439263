
import EditorImage from 'Editors/EditorImage'
import EditorTipTap from 'Editors/EditorTipTap'
import EditorLink from 'Editors/EditorLink'
import EditorProductList from 'Editors/EditorProductList'
import Spinner from '../ui/Spinner'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    EditorImage,
    // eslint-disable-next-line vue/no-unused-components
    EditorTipTap,
    // eslint-disable-next-line vue/no-unused-components
    EditorLink,
    EditorProductList,
    Spinner,
  },
  data() {
    return {
      inView: false,
    }
  },
  methods: {
    currencyNameSwitch(price) {
      if (!price) return
      return price.replace('HRK', 'Kn')
    },
  },
}
